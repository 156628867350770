import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography } from "@mui/material";

const EntryDetail = ({ qty, price, date, title }) => {
  return (
    <>
      <Box display={"flex"} sx={{ gap: "10px", alignItems: "center" }}>
        <Box>
          <CheckCircleIcon sx={{ color: "#787878" }} />
        </Box>
        <Box>
          <Typography sx={{ color: "#787878" }}>{title}</Typography>
          <Typography sx={{ color: "#787878" }}>{date}</Typography>
          <Typography sx={{ color: "#787878" }}>
            Qty {qty} @ {price}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
export default EntryDetail;
