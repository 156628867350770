import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../Loader/LoadingComponent";

function PaginationTable({ columns, rows, filterValues, isLoading }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  // const filteredRows = filterAndSortRows(rows, filterValues);

  const displayedRows = rows?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row) => {
    if (row.candle__symbol) {
      navigate(`/ath/details/${row.candle__symbol}`);
    } else {
      navigate(
        `/ath/details/${row.candle__symbols.props.children[0]?.props.children}`
      );
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ padding: "50px" }}>
        <LoadingComponent />
      </Box>
    );
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: isSmUp ? 800 : 400, width: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  sx={{ background: "#fff" }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows?.map((row, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(row)}
                hover
                style={{ cursor: "pointer" }}
              >
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell key={column.id + index} align={column.align}>
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows?.length ? rows?.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default PaginationTable;
