import { createSlice } from "@reduxjs/toolkit";
import { fetchAthData } from "./athActions";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  filterValues: {
    status: "",
    overall_status: "",
    search: "",
    sort: "",
    strategy: "",
    preferred_only: "",
  },
};

const athSlice = createSlice({
  name: "ath",
  initialState,
  reducers: {
    setFilterValues: (state, action) => {
      state.filterValues = { ...state.filterValues, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAthData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAthData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchAthData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilterValues } = athSlice.actions;
export default athSlice.reducer;
