import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorComponent from "../Alert/ErrorComponent";
import SuccessComponent from "../Alert/SuccessComponent";
import LoadingButton from "../Button/LoadingButton";
import FormikPhoneFieldCode from "../Formik/FormikPhoneFieldCode";
import FormikTextField from "../Formik/FormikTextField";
import LoginUi from "../LoginUi/LoginUi";
import { useAuth } from "../Register/AuthContext";

const LoginAuth = () => {
  const {
    errorMessage,
    successMessage,
    otpSent,
    setOtpSent,
    registerUser,
    loginUser,
  } = useAuth();

  const initialValues = {
    mobile_number: "",
    otp_code: "",
  };

  const validationSchema = Yup.object({
    mobile_number: Yup.string().required("Mobile number is required"),
    otp_code: Yup.string().label("OTP"),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    await loginUser(values);
    setSubmitting(false);
  };

  return (
    <>
      <LoginUi
        title="Login"
        subtitle={
          otpSent
            ? "An OTP has been sent to the registered mobile number"
            : "Please enter login details"
        }
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, isSubmitting, errors }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} pt={3}>
                {!otpSent ? (
                  <Grid item xs={12}>
                    <FormikPhoneFieldCode
                      name="mobile_number"
                      label="Mobile Number"
                      fullWidth
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <FormikTextField
                      name="otp_code"
                      label="Enter OTP"
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      type="submit"
                      fullWidth
                      isLoading={isSubmitting}
                      btnText={otpSent ? "Verify OTP" : "Login"}
                      loadingText={otpSent ? "Verifying OTP" : "Logging"}
                    />
                  </Box>
                </Grid>
                {errorMessage && (
                  <Grid item xs={12}>
                    <ErrorComponent message={errorMessage} />
                  </Grid>
                )}

                {successMessage && (
                  <Grid item xs={12}>
                    <SuccessComponent message={successMessage} />
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        </Formik>
      </LoginUi>
    </>
  );
};
export default LoginAuth;
