import { configureStore } from "@reduxjs/toolkit";
import athReducer from "./ath/athSlice";

const store = configureStore({
  reducer: {
    ath: athReducer,
  },
});

export default store;
