import { Box, Card, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAlertListApi } from "../../service/api";
import ErrorComponent from "../Alert/ErrorComponent";
import PaginationTable from "../PaginatedTable/PaginationTable";

const AlertsDetails = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cc = searchParams.get("cc");
  const date = searchParams.get("date");

  const getData = async () => {
    setIsLoading(true);
    try {
      const data = { cc, date };
      const response = await getAlertListApi(data);
      setData(response);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (error) {
    return <ErrorComponent message={error} />;
  }

  const columns = [
    { id: "candle__symbol", label: "Symbol" },
    { id: "candle__date", label: "Date" },
    {
      id: "cc_date",
      label: "CC Date",
    },
    { id: "cc_entry_price", label: "CC Entry Price" },
  ];

  const rows = data?.data?.map((item) => ({
    candle__date: moment(item?.candle__date).format("DD-MMM-YYYY"),
    candle__symbol: item?.candle__symbol,
    cc_date: moment(item?.cc_date).format("DD-MMM-YYYY"),
    cc_entry_price: item?.cc_entry_price,
  }));

  return (
    <>
      <Box sx={{ display: "flex", gap: "50px" }}>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: "550" }}>
            CC
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "550" }}>
            {cc}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" sx={{ fontWeight: "550" }}>
            Date
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "550" }}>
            {moment(date).format("DD-MMM-YYYY")}
          </Typography>
        </Box>
      </Box>

      <Box mt={5}>
        <Card>
          {data?.data?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <Typography variant="h6">No Record Found</Typography>
            </Box>
          ) : (
            <PaginationTable
              columns={columns}
              rows={rows}
              isLoading={isLoading}
            />
          )}
        </Card>
      </Box>
    </>
  );
};
export default AlertsDetails;
