import RegisterAuth from "../components/Register/RegisterAuth";

const RegisterPage = () => {
  return (
    <>
      <RegisterAuth />
    </>
  );
};
export default RegisterPage;
