import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Card, Tab } from "@mui/material";
import { useState } from "react";
import AthDataListData from "../components/AthComponent/AthDataList";
import AthDataListPortfolioData from "../components/AthPortfolio/AthDataListPortfolioData";
import Dashboard from "../components/Dashboard/Dashboard";

const AthDataList = () => {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Dashboard>
        <Card>
          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="ANALYSIS" value="1" />
                <Tab label="PORTFOLIO MCC" value="2" />
                <Tab label="PORTFOLIO WCC" value="3" />
                <Tab label="PORTFOLIO DCC" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "0px", paddingTop: "15px" }}>
              <AthDataListData type="analysis" />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "0px", paddingTop: "15px" }}>
              <AthDataListPortfolioData type="MCC" />
            </TabPanel>
            <TabPanel value="3" sx={{ padding: "0px", paddingTop: "15px" }}>
              <AthDataListPortfolioData type="WCC" />
            </TabPanel>
            <TabPanel value="4" sx={{ padding: "0px", paddingTop: "15px" }}>
              <AthDataListPortfolioData type="DCC" />
            </TabPanel>
          </TabContext>
        </Card>
      </Dashboard>
    </>
  );
};
export default AthDataList;
