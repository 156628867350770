import { createTheme } from "@mui/material";

const baseTheme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: 13,
  },
  palette: {
    type: "light",
    background: {
      default: "#f2f2f2",
    },
    primary: {
      main: "#00f381",
      thin: "#00f381",
    },
    secondary: {
      main: "#000",
    },
    danger: {
      main: "#000",
    },
    darkText: {
      main: "#101010",
    },
    light: {
      main: "#000",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: "50px",
        },
        icon: {
          color: "#410b76",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "--TextField-brandBorderColor": "red",
          "--TextField-brandBorderHoverColor": "#B2BAC2",
          "--TextField-brandBorderFocusedColor": "#6F7E8C",
          "& label.Mui-focused": {
            color: "var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "50px",
          fontFamily: '"Poppins", sans-serif',
          my: "10px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          padding: "10px",
          fontFamily: '"Poppins", sans-serif',
          backgroundColor: "#ffffff",
        },
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: '"Poppins", sans-serif',
        boxShadow: "none",
      },
    },
    MuiButtonBaseRoot: {
      boxShadow: "none",
    },
    MuiCardActionArea: {
      root: {
        fontFamily: '"Poppins", sans-serif',
      },
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "red",
        },
        "&:hover $notchedOutline": {
          borderColor: "red",
        },
      },
    },
  },
});

export { baseTheme };
