import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Card, Chip, IconButton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const AthHeader = ({ data }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const backToPage = () => {
    navigate(-1);
  };

  return (
    <>
      <Card sx={{ padding: "10px" }}>
        <Box display={"flex"} gap={"10px"} alignItems={"center"}>
          <Box>
            <Box display={"flex"} alignItems={"center"} gap={"10"}>
              <Box>
                <IconButton aria-label="back" onClick={backToPage}>
                  <ChevronLeftIcon />
                </IconButton>
              </Box>
              <Box>
                <Typography variant="h5" sx={{ fontWeight: "600" }}>
                  {data.symbol}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box my={2}>
            <Chip
              label={id}
              sx={{
                borderRadius: "4px",
                background: "#e8e8e8",
                color: "#000",
                fontSize: "17px",
              }}
            />
          </Box>
        </Box>
        {/* 
        <Box sx={{ display: "flex", gap: "30px", flexDirection: "row" }}>
          <CompPriceDetail headTitle={"Market Cap"} title={"Market Cap"} />
          <CompPriceDetail headTitle={"Date of Inception"} title={"Date"} />
          <CompPriceDetail headTitle={"Company Age"} title={"Age"} />
        </Box> */}
      </Card>
    </>
  );
};
export default AthHeader;
