import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const MAIN_URL = process.env.REACT_APP_MAIN_URL;
const userProfileString = localStorage.getItem("userProfile");

const parsedUserProfile = userProfileString
  ? JSON.parse(userProfileString)
  : null;

export const createRegisterApi = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/register/`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const getStrategyAthApi = async (filterValues) => {
  try {
    let url = `${BASE_URL}/equity/strategy-ath/?strategy=${
      filterValues.strategy ? filterValues.strategy : "All"
    }`;

    if (filterValues.overall_status) {
      url += `&overall_status=${filterValues.overall_status}`;
    }

    if (filterValues.status) {
      url += `&status=${filterValues.status}`;
    }

    if (filterValues.sort) {
      url += `&sort_by=${filterValues.sort}`;
    }

    if (filterValues.preferred_only) {
      url += `&preferred_only=${filterValues.preferred_only}`;
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${parsedUserProfile.access}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const getStrategyPortfolioAthApi = async (filterValues) => {
  try {
    let url = `${BASE_URL}/equity/strategy-ath?strategy=${
      filterValues.strategy ? filterValues.strategy : "All"
    }`;

    if (filterValues.overall_status) {
      url += `&overall_status=${filterValues.overall_status}`;
    }

    if (filterValues.status) {
      url += `&status=${filterValues.status}`;
    }

    if (filterValues.sort) {
      url += `&sort_by=${filterValues.sort}`;
    }

    if (filterValues.preferred_only) {
      url += `&preferred_only=${filterValues.preferred_only}`;
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${parsedUserProfile.access}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const getByStrategyAthApi = async (data) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/equity/strategy-ath/${data}/`,
      {
        headers: {
          Authorization: `Bearer ${parsedUserProfile.access}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

const getBatchDetails = async () => {
  try {
    const response = await axios.get(
      `${MAIN_URL}/api/aps-care-courses?populate=*&filters[status][$eq]=true`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const findUserPaid = async (data) => {
  try {
    const getBatchDetailsData = await getBatchDetails();

    console.log(data);

    const batchIds = getBatchDetailsData.data.map((item) =>
      encodeURIComponent(item.attributes.batch.data.attributes.batch_id)
    );

    const batchFilters = batchIds
      .map((batchId) => `filters[batch][$eq]=${batchId}`)
      .join("&");

    const response = await axios.get(
      `${MAIN_URL}/api/payments?${batchFilters}&filters[phone][$eq]=${encodeURIComponent(
        data.mobile_number
      )}`
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const getAlertListApi = async (data) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/alert/confirmation-candle-alert/?date=${data.date}&cc_type=${data.cc}`,
      {
        headers: {
          Authorization: `Bearer ${parsedUserProfile.access}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const sumbitOtp = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/submit-otp/`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const addToPortfolioApi = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/preference/`, data, {
      headers: {
        Authorization: `Bearer ${parsedUserProfile.access}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const removeFromPortfolioApi = async (data) => {
  try {
    const response = await axios.delete(`${BASE_URL}/preference/`, {
      headers: {
        Authorization: `Bearer ${parsedUserProfile.access}`,
      },
      data: data,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const createLoginApi = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login/`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};
