import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addToPortfolioApi } from "../../service/api";
import { fetchAthData } from "../../store/ath/athActions";
import {
  selectAthData,
  selectError,
  selectFilterValues,
  selectIsLoading,
} from "../../store/ath/athSelectors";
import { setFilterValues } from "../../store/ath/athSlice";
import ErrorComponent from "../Alert/ErrorComponent";
import PaginationTable from "../PaginatedTable/PaginationTable";
import SelectFilter from "./Filters/SelectFilter";

const AthDataList = ({ type }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectAthData);
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const filterValues = useSelector(selectFilterValues);

  useEffect(() => {
    const newFilterValues = { ...filterValues };
    if (type === "portfolio") {
      newFilterValues.preferred_only = "true";
    }
    if (type === "analysis") {
      newFilterValues.preferred_only = "";
    }
    dispatch(setFilterValues(newFilterValues));
  }, [dispatch, type]);

  useEffect(() => {
    dispatch(fetchAthData());
  }, [dispatch, filterValues, type]);

  const columns = [
    { id: "candle__symbols", label: "Symbol" },
    { id: "rh", label: "ATH" },
    {
      id: "cc_high_low",
      label: (
        <Typography>
          CC: Close <br /> CC: High
        </Typography>
      ),
    },
    { id: "cc", label: "CC" },
    { id: "hh_till_date", label: "HH Till Date" },
    { id: "pm_10sma", label: "PM 10SMA" },
    { id: "pm_20sma", label: "PM 20SMA" },
    { id: "target", label: "Range" },
    { id: "sl", label: "IDL" },
    { id: "Action", label: "Action" },
  ];

  const rangeItem = [
    { label: "No Target", value: "<12.5" },
    { label: "Target 1", value: "12.5" },
    { label: "Target 2", value: "25" },
    { label: "Target 3", value: "50" },
    { label: "Target 4", value: "75" },
    { label: "Target 5", value: "100" },
  ];

  const updateFilterValue = (filterName, value) => {
    const newFilterValues = { ...filterValues, [filterName]: value };
    if (type === "portfolio") {
      newFilterValues.preferred_only = "true";
    }
    if (type === "analysis") {
      newFilterValues.preferred_only = "";
    }
    dispatch(setFilterValues(newFilterValues));
  };

  const addToPortfolio = async (data) => {
    try {
      await addToPortfolioApi({
        symbol_id: data,
      });
      dispatch(fetchAthData());
      toast.success("Added to Portfolio");
    } catch (e) {
      console.log(e);
    }
  };

  const rows = data.analysis
    ?.filter((item) =>
      filterValues.search
        ? item.candle__symbol
            .toLowerCase()
            .includes(filterValues.search.toLowerCase())
        : true
    )
    .map((item) => ({
      candle__symbols: (
        <>
          <Typography>{item?.candle__symbol}</Typography>
          <Box sx={{ display: "flex", gap: "4px" }}>
            <Chip
              size="small"
              label={item?.strategy}
              sx={{
                borderRadius: "4px",
                background: "#d4eee2",
                fontSize: "10px",
                color: "#487a63",
              }}
            />
            <Chip
              size="small"
              label={
                item?.overall_status === "CLOSE-NOCC"
                  ? "CLOSE"
                  : item?.overall_status === "CLOSE-CC"
                  ? "CC"
                  : item?.overall_status
              }
              sx={{
                borderRadius: "4px",
                background: "#d4eee2",
                fontSize: "10px",
                color: "#487a63",
              }}
            />
          </Box>
        </>
      ),
      rh: (
        <>
          <Typography sx={{ fontSize: "14px" }}>
            {item.cc_prev_month_ath.toFixed(2)}
          </Typography>
          <Typography>
            {moment(item.cc_prev_month_ath_date).format("DD-MMM-YYYY")}
          </Typography>
        </>
      ),
      cc_high_low: (
        <>
          <Typography sx={{ fontSize: "14px" }}>
            {item?.overall_status === "CLOSE-NOCC"
              ? ""
              : item.cc_close.toFixed(2)}
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            {item?.overall_status === "CLOSE-NOCC"
              ? ""
              : item.cc_highest_high.toFixed(2)}
          </Typography>
        </>
      ),
      cc: (
        <>
          <Typography sx={{ fontSize: "14px" }}>
            {item?.overall_status === "CLOSE-NOCC"
              ? ""
              : moment(item.cc_highest_high_date).format("DD-MMM-YYYY")}
          </Typography>
        </>
      ),
      hh_till_date: (
        <>
          <Typography sx={{ fontSize: "14px" }}>
            {item?.overall_status === "CLOSE-NOCC"
              ? ""
              : item.highest_high_after_start_point.toFixed(2)}
          </Typography>
        </>
      ),
      pm_10sma: (
        <>
          <Typography sx={{ fontSize: "14px" }}>
            {item.status_label === "Target 5"
              ? item?.overall_status === "CLOSE-NOCC"
                ? ""
                : item.simple_moving_average_10.toFixed(2)
              : ""}
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            {item.status_label === "Target 5"
              ? item?.overall_status === "CLOSE-NOCC"
                ? ""
                : "31-May-2024"
              : ""}
          </Typography>
        </>
      ),
      pm_20sma: (
        <>
          <Typography sx={{ fontSize: "14px" }}>
            {item.status_label === "Target 5"
              ? ""
              : item?.overall_status === "CLOSE-NOCC"
              ? ""
              : item.simple_moving_average_20.toFixed(2)}
          </Typography>
          <Typography sx={{ fontSize: "14px" }}>
            {item.status_label === "Target 5"
              ? ""
              : item?.overall_status === "CLOSE-NOCC"
              ? ""
              : "31-May-2024"}
          </Typography>
        </>
      ),
      target: (
        <>
          <Typography sx={{ fontSize: "14px", color: "green" }}>
            {
              rangeItem.find((itemaa) => itemaa.label === item.status_label)
                ?.value
            }
          </Typography>
        </>
      ),
      sl: (
        <>
          <Typography sx={{ fontSize: "14px" }}>
            {item?.overall_status === "CLOSE-NOCC" ? "" : item.stop_loss_value}
          </Typography>
        </>
      ),
      Action: (
        <>
          {item?.is_preferred ? (
            <IconButton aria-label="add" size="small" disabled>
              <AddCircleOutlinedIcon sx={{ color: "#4c99f34d" }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => addToPortfolio(item.equity_symbol_id)}
              aria-label="add"
              size="small"
            >
              <AddCircleOutlinedIcon sx={{ color: "#4c99f3" }} />
            </IconButton>
          )}
        </>
      ),
    }));

  if (error) {
    return <ErrorComponent message={error} />;
  }

  const overall_status_list = [
    { title: "CC", value: "CLOSE-CC" },
    { title: "Close", value: "CLOSE-NOCC" },
    { title: "Open", value: "OPEN" },
  ];

  const statusList = [
    { title: "All", value: "All" },
    { title: "<12.5", value: 0 },
    { title: "12.5", value: 1 },
    { title: "25", value: 2 },
    { title: "50", value: 3 },
    { title: "75", value: 4 },
    { title: "100", value: 5 },
  ];

  const sort = [
    { title: "None", value: "" },
    { title: "Name", value: "candle__symbol" },
    // { title: "CC", value: "cc_date" },
    // { title: "Target Status", value: "status" },
  ];

  const strategy = [
    { title: "All", value: "All" },
    { title: "MCC", value: "MCC" },
    { title: "WCC", value: "WCC" },
    { title: "DCC", value: "DCC" },
  ];

  // const updateFilterValue = (filterName, value) => {
  //   dispatch(setFilterValues({ [filterName]: value }));
  // };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Box sx={{ flex: 3, display: "flex", gap: 1 }}>
          <IconButton aria-label="clear">
            <FilterListIcon />
          </IconButton>
          <SelectFilter
            list={strategy}
            label="Confirmation Candle"
            setValue={(value) => updateFilterValue("strategy", value)}
          />
        </Box>
        <Box sx={{ flex: 6 }}>
          <Box>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              onChange={(e) => updateFilterValue("search", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
        </Box>
        <Box sx={{ flex: 2 }}>
          <SelectFilter
            list={overall_status_list}
            label="Overall Status"
            setValue={(value) => updateFilterValue("overall_status", value)}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <SelectFilter
            list={statusList}
            label="Range"
            setValue={(value) => updateFilterValue("status", value)}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <SelectFilter
            list={sort}
            label="Sort By"
            setValue={(value) => updateFilterValue("sort", value)}
          />
        </Box>
      </Box>
      <PaginationTable columns={columns} rows={rows} isLoading={isLoading} />
    </>
  );
};

export default AthDataList;
