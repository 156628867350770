import { Box, Typography } from "@mui/material";
import ryttlogo from "../../assets/Images/asmitapatel.png";

const LoginUi = ({ children, title, subtitle }) => {
  return (
    <>
      <Box sx={{ background: "#00f381", height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              background: "#fff",
              padding: "25px",
              borderRadius: "10px",
              width: "350px",
            }}
          >
            <img
              src={ryttlogo}
              style={{
                height: "40px",
                width: "100%",
                objectFit: "contain",
                paddingTop: "10px",
              }}
              alt="Ryt Logo"
            />
            <Box mt={2}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "500", textAlign: "center" }}
              >
                {title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "500",
                  textAlign: "center",
                  marginTop: "6px",
                }}
              >
                {subtitle}
              </Typography>
            </Box>
            {children}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default LoginUi;
