import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";

const SelectFilter = ({ list, label, setValue }) => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
    setValue(event.target.value);
  };

  return (
    <FormControl size="small" fullWidth>
      <InputLabel id={`${label}-simple-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-simple-select-label`}
        id={`${label}-simple-select-label`}
        value={age}
        label="Age"
        onChange={handleChange}
      >
        {list?.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectFilter;
