import { Box, Typography } from "@mui/material";

const CompPriceDetail = ({ headTitle, title, subtitle }) => {
  return (
    <Box>
      <Typography variant="body1" sx={{ color: "gray" }}>
        {headTitle}
      </Typography>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </Box>
  );
};
export default CompPriceDetail;
