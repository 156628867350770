import { Navigate, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./components/Register/AuthContext";
import AthDataList from "./pages/AthDataList";
import AthDetail from "./pages/AthDetail";
import LoginPage from "./pages/LoginPage";
import PriceAlertDetail from "./pages/PriceAlertDetail";
import RegisterPage from "./pages/RegisterPage";
import {
  UserProfileProvider,
  useUserProfile,
} from "./service/UserProfileProvider";

const AppRoute = () => {
  const { userProfile } = useUserProfile();

  return (
    <AuthProvider>
      <UserProfileProvider>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={userProfile ? "/ath" : "/login"} />}
          ></Route>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/ath" element={<AthDataList />} />
          <Route path="/ath/details/:id" element={<AthDetail />} />
          <Route path="/cc-alert" element={<PriceAlertDetail />} />
        </Routes>
      </UserProfileProvider>
    </AuthProvider>
  );
};
export default AppRoute;
