import {
  Box,
  Card,
  Chip,
  Grid,
  Step,
  Stepper,
  Typography,
} from "@mui/material";
import moment from "moment";
import CompPriceDetail from "./CompPriceDetail";
import EntryDetail from "./EntryDetail";

const ChipSection = ({ labels }) => (
  <Box sx={{ display: "flex", gap: "5px" }}>
    {labels.map((label, index) => (
      <Chip
        key={index}
        size="small"
        label={label.name}
        sx={{
          borderRadius: "4px",
          background: label.color,
          fontSize: "15px",
          color: "#fff",
        }}
      />
    ))}
  </Box>
);

const SectionTitle = ({ title }) => (
  <Box mt={3}>
    <Typography variant="h6" sx={{ fontSize: "16px" }}>
      {title}
    </Typography>
  </Box>
);

const getReturnValue = (targetDetails) => {
  const lastAchievedIndex = targetDetails
    ?.reverse()
    ?.findIndex((item) => item.is_achieved);

  if (lastAchievedIndex === -1) {
    return undefined;
  }

  const achievedTarget = targetDetails?.[lastAchievedIndex];

  switch (achievedTarget?.status) {
    case 5:
      return 3;
    case 4:
      return 4;
    case 3:
      return 5;
    case 2:
      return 6;
    case 1:
      return 8;
    default:
      return undefined;
  }
};

const MccDetailCard = ({ mccData, value, smaten, smatwenty }) => {
  const chipLabels = [
    { name: value, color: "#6e6e6e" },
    { name: "CC", color: "#969696" },
  ];

  const qtyItems = [2, 2, 1, 1, 1];

  const renderStep = (step, index, prefix) => (
    <Step key={index}>
      <EntryDetail
        title={`${prefix} ${step.status}`}
        qty={qtyItems[index]}
        price={step.status_price}
        date={step.status_date}
      />
    </Step>
  );

  const stopLossStep = (step, index, qty, prefix) => (
    <Step key={index}>
      <EntryDetail
        title={`${prefix} ${index + 1}`}
        qty={qty}
        price={step.status_price}
        date={step.status_date}
      />
    </Step>
  );

  const result = getReturnValue(mccData?.target_details);

  return (
    <Card>
      <Box sx={{ padding: "10px" }}>
        <ChipSection labels={chipLabels} />

        <Box mt={3}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <CompPriceDetail
                headTitle={"ATH"}
                title={mccData?.cc_prev_month_ath}
                subtitle={moment(mccData?.cc_prev_month_ath_date).format(
                  "DD-MMM-YYYY"
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <CompPriceDetail
                headTitle={"CC"}
                title={mccData?.cc_highest_high}
                subtitle={moment(mccData?.cc_highest_high_date).format(
                  "DD-MMM-YYYY"
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <CompPriceDetail headTitle={"PSMMA10"} title={smaten} />
            </Grid>
            <Grid item xs={6}>
              <CompPriceDetail headTitle={"PSMMA20"} title={smatwenty} />
            </Grid>
          </Grid>
        </Box>

        <SectionTitle title="ENTRY DETAILS" />
        <Box mt={2}>
          <EntryDetail
            title={"Entry"}
            qty={"10"}
            price={mccData?.entry_price}
            date={mccData?.entry_price_date}
          />
        </Box>

        <SectionTitle title="TARGET DETAILS" />
        <Box mt={2}>
          <Stepper orientation="vertical">
            {mccData?.target_details?.map((step, index) =>
              renderStep(step, index, "Target")
            )}
          </Stepper>
        </Box>

        <SectionTitle title="STOPLOSS DETAILS" />
        <Box mt={2}>
          <Stepper orientation="vertical">
            {mccData?.stop_loss_details?.map((step, index) =>
              stopLossStep(step, index, result, "SL")
            )}
          </Stepper>
        </Box>
      </Box>
    </Card>
  );
};

export default MccDetailCard;
