import AlertsDetails from "../components/AlertsDetails/AlertsDetails";
import Dashboard from "../components/Dashboard/Dashboard";

const PriceAlertDetail = () => {
  return (
    <Dashboard>
      <AlertsDetails />
    </Dashboard>
  );
};
export default PriceAlertDetail;
