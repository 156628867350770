import LoginAuth from "../components/Login/LoginAuth";

const LoginPage = () => {
  return (
    <>
      <LoginAuth />
    </>
  );
};
export default LoginPage;
