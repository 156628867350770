import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getByStrategyAthApi } from "../../service/api";
import ErrorComponent from "../Alert/ErrorComponent";
import LoadingComponent from "../Loader/LoadingComponent";
import AthHeader from "./AthHeader";
import MccDetailCard from "./MccDetailCard";

const AthDetails = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const getAthData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const fetchedData = await getByStrategyAthApi(id);
      setData(fetchedData);
    } catch (err) {
      setError("An error occurred while fetching data.");
      console.error("Error fetching data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAthData();
  }, [id]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (error) {
    return <ErrorComponent message={error} />;
  }

  if (!data) {
    return <ErrorComponent message="No data available." />;
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AthHeader data={data} />
        </Grid>
        <Grid item xs={4}>
          <MccDetailCard
            mccData={data.mcc}
            value={"MCC"}
            smaten={data.simple_moving_average_10}
            smatwenty={data.simple_moving_average_20}
          />
        </Grid>
        <Grid item xs={4}>
          <MccDetailCard
            mccData={data.dcc}
            value={"DCC"}
            smaten={data.simple_moving_average_10}
            smatwenty={data.simple_moving_average_20}
          />
        </Grid>
        <Grid item xs={4}>
          <MccDetailCard
            mccData={data.wcc}
            value={"WCC"}
            smaten={data.simple_moving_average_10}
            smatwenty={data.simple_moving_average_20}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AthDetails;
