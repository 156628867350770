import AthDetails from "../components/AthDetails/AthDetails";
import Dashboard from "../components/Dashboard/Dashboard";

const AthDetail = () => {
  return (
    <>
      <Dashboard>
        <AthDetails />
      </Dashboard>
    </>
  );
};
export default AthDetail;
