import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createLoginApi,
  createRegisterApi,
  findUserPaid,
  sumbitOtp,
} from "../../service/api";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const userProfileData = localStorage.getItem("userProfile");
    if (userProfileData) {
      setUserProfile(JSON.parse(userProfileData));
    } else {
      setUserProfile(null);
    }
  }, []);

  const registerUser = async (values) => {
    try {
      if (!otpSent) {
        const response = await findUserPaid(values);
        if (response && response.data.length > 0) {
          const finalApi = await createRegisterApi({
            email_id: values.email_id,
            first_name: values.first_name,
            last_name: values.last_name,
            mobile_number: values.mobile_number.slice(-10),
          });
          if (finalApi) {
            setSuccessMessage("Account Created Successfully");
            setOtpSent(true);
          }
        } else {
          setErrorMessage("User not found or unable to send OTP.");
        }
      } else {
        const sumitOtpResponse = await sumbitOtp({
          mobile_number: values.mobile_number.slice(-10),
          otp_code: values.otp_code,
        });
        if (sumitOtpResponse) {
          localStorage.setItem("userProfile", JSON.stringify(sumitOtpResponse));
          navigate("/ath");
          // window.location.href = "/ath";
        }
      }
    } catch (error) {
      console.error("Registration failed:", error);
      setErrorMessage("Registration failed. Please try again.");
    }
  };

  const loginUser = async (values) => {
    try {
      if (!otpSent) {
        const response = await createLoginApi({
          mobile_number: values.mobile_number.slice(-10),
        });
        if (response) {
          setSuccessMessage("Otp Sended Successfully");
          setErrorMessage(null);
          setOtpSent(true);
        } else {
          setErrorMessage("Invalid Phone Number");
        }
      } else {
        const sumitOtpResponse = await sumbitOtp({
          mobile_number: values.mobile_number.slice(-10),
          otp_code: values.otp_code,
        });
        if (sumitOtpResponse) {
          localStorage.setItem("userProfile", JSON.stringify(sumitOtpResponse));
          // navigate("/ath");
          window.location.href = "/ath";
        }
      }
    } catch (error) {
      console.error("Login failed:", error);
      setErrorMessage("Login failed. Please try again.");
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("userProfile");
    navigate("/login");
    setUserProfile(null);
  };

  return (
    <AuthContext.Provider
      value={{
        errorMessage,
        successMessage,
        otpSent,
        userProfile,
        setOtpSent,
        registerUser,
        loginUser,
        logoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
