import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorComponent from "../Alert/ErrorComponent";
import SuccessComponent from "../Alert/SuccessComponent";
import LoadingButton from "../Button/LoadingButton";
import FormikPhoneFieldCode from "../Formik/FormikPhoneFieldCode";
import FormikTextField from "../Formik/FormikTextField";
import LoginUi from "../LoginUi/LoginUi";
import { useAuth } from "./AuthContext";

const RegisterAuth = () => {
  const { errorMessage, successMessage, otpSent, setOtpSent, registerUser } =
    useAuth();

  const initialValues = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_id: "",
    otp_code: "",
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    mobile_number: Yup.string().required("Mobile number is required"),
    email_id: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    otp_code: Yup.string().label("OTP"),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    await registerUser(values);
    setSubmitting(false);
  };

  return (
    <LoginUi
      title="Create Account"
      subtitle={
        otpSent
          ? "An OTP has been sent to the registered mobile number"
          : "Please enter the following details to proceed"
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} pt={3}>
              {!otpSent ? (
                <>
                  <Grid item xs={6}>
                    <FormikTextField
                      name="first_name"
                      label="First Name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      name="last_name"
                      label="Last Name"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikPhoneFieldCode
                      name="mobile_number"
                      label="Mobile Number"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikTextField
                      name="email_id"
                      label="Email Id"
                      fullWidth
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <FormikTextField
                    name="otp_code"
                    label="Enter OTP"
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    fullWidth
                    isLoading={isSubmitting}
                    btnText={otpSent ? "Verify OTP" : "Register"}
                    loadingText={otpSent ? "Verifying OTP" : "Registering"}
                  />
                </Box>
              </Grid>

              {errorMessage && (
                <Grid item xs={12}>
                  <ErrorComponent message={errorMessage} />
                </Grid>
              )}

              {successMessage && (
                <Grid item xs={12}>
                  <SuccessComponent message={successMessage} />
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </Formik>
    </LoginUi>
  );
};

export default RegisterAuth;
