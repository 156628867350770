import { createAsyncThunk } from "@reduxjs/toolkit";
import { getStrategyAthApi } from "../../service/api";
import { selectAthData, selectFilterValues } from "./athSelectors";

export const fetchAthData = createAsyncThunk(
  "ath/fetchAthData",
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const existingData = selectAthData(state);
    const filterValues = selectFilterValues(state);

    if (
      !existingData ||
      existingData.length === 0 ||
      filterValues.overall_status.length > 1 ||
      filterValues.status.length > 1 ||
      filterValues.sort.length > 1 ||
      filterValues.strategy.length > 1 ||
      filterValues.preferred_only.length > 0
    ) {
      try {
        const fetchedData = await getStrategyAthApi(filterValues);
        return fetchedData;
      } catch (err) {
        return rejectWithValue(err.response?.data || err.message);
      }
    }

    return existingData;
  }
);

export const fetchAthDataPortfolio = createAsyncThunk(
  "ath/fetchAthData",
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const existingData = selectAthData(state);
    const filterValues = selectFilterValues(state);

    if (
      !existingData ||
      existingData.length === 0 ||
      filterValues.overall_status.length > 1 ||
      filterValues.status.length > 1 ||
      filterValues.sort.length > 1 ||
      filterValues.strategy.length > 1 ||
      filterValues.preferred_only.length > 0
    ) {
      try {
        const fetchedData = await getStrategyAthApi(filterValues);
        return fetchedData;
      } catch (err) {
        return rejectWithValue(err.response?.data || err.message);
      }
    }

    return existingData;
  }
);
