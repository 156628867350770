import { ThemeProvider } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoute from "./AppRoute.jsx";
import { UserProfileProvider } from "./service/UserProfileProvider.js";
import { baseTheme } from "./theme.js";

function App() {
  return (
    <>
      <ThemeProvider theme={baseTheme}>
        <UserProfileProvider>
          <Router>
            <AppRoute />
            <ToastContainer
              theme="dark"
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Router>
        </UserProfileProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
