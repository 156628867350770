import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Card,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ryttlogo from "../../assets/Images/asmitapatel.png";
import { useAuth } from "../Register/AuthContext";

const drawerWidth = 240;

const athItems = [
  {
    icon: "ATH",
    title: "ATH Scanner",
    link: "/ath",
  },
];

function Dashboard({ children }) {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { logoutUser } = useAuth();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const clickLogout = () => {
    logoutUser();
    window.location.reload();
  };

  const drawer = (
    <div>
      <img
        src={ryttlogo}
        style={{
          height: "56px",
          width: "100%",
          objectFit: "contain",
          paddingTop: "10px",
        }}
        alt="Ryt Logo"
      />

      <Divider />
      <List>
        {athItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={() => navigate(item.link)}>
              <ListItemIcon>
                <Card
                  sx={{
                    backgroundColor: "#00f381",
                    height: "30px",
                    width: "40px",
                    padding: "6px",
                    color: "#fff",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "500", color: "#000" }}
                  >
                    {item.icon}
                  </Typography>
                </Card>
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#fff",
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" fontWeight={500}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ gap: "10px" }}
            >
              <Card
                sx={{
                  backgroundColor: "#00f381",
                  padding: "6px",
                  color: "#fff",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "bold", color: "#000" }}
                >
                  ATH
                </Typography>
              </Card>{" "}
              All Time High Scanner
            </Box>
          </Typography>
          <Button
            onClick={clickLogout}
            variant="outlined"
            sx={{ color: "#000" }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

export default Dashboard;
